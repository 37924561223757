import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import IngredientForm from 'components/main/Ingredients/Add/IngredientForm';
// interfaces, schema, graphql, constants, helpers
import { convertEditorStateToHtml, formatIngredientInfo } from 'lib/helper';
import { AddNewIngredientFormType } from 'interfaces';
import { addNewIngredientSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { SupplierType, IngredientSupplierInput, useCreateIngredientMutation } from 'generated/graphql';
import {
  HTTP_STATUS,
  ADD_INGREDIENT_TEXT,
  INGREDIENT_IS_CREATING_TEXT,
  addNewIngredientInitialValues,
} from 'constants/index';

const AddIngredient: FC = () => {
  const navigate = useNavigate();

  const methods = useForm<AddNewIngredientFormType>({
    defaultValues: addNewIngredientInitialValues,
    resolver: yupResolver(addNewIngredientSchema),
  });

  const { handleSubmit } = methods;

  const [createIngredient, { loading: createIngredientLoading }] = useCreateIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createIngredient } = data;
      const { response } = createIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewIngredientFormType> = async (data) => {
    const {
      type,
      name,
      cost,
      amount,
      potency,
      category,
      shelfLife,
      latinName,
      productTypes,
      subCategory,
      bulkDensity,
      lossOnDrying,
      originCountry,
      tappedDensity,
      tradeMarkName,
      scientificName,
      suggestedOverage,
      averageParticleSize,
      manufacturingCountry,
      allergens,
      primarySupplier,
      secondarySupplier,
      tertiarySupplier,

      gummyOverage,

      nutrients,
      nutrientName,
      showB3,
    } = data || {};

    const potencyInDecimal = Number(potency) / 100;

    const { value: primarySupplierId } = primarySupplier;
    const { value: secondarySupplierId } = secondarySupplier;
    const { value: tertiarySupplierId } = tertiarySupplier;

    const suppliersList: IngredientSupplierInput[] = [];
    if (primarySupplierId) {
      suppliersList.push({ type: SupplierType.Primary, supplier: primarySupplierId });
    }

    if (secondarySupplierId) {
      suppliersList.push({ type: SupplierType.Secondary, supplier: secondarySupplierId });
    }

    if (tertiarySupplierId) {
      suppliersList.push({ type: SupplierType.Tertiary, supplier: tertiarySupplierId });
    }

    const { value: categoryId } = category || {};
    const { value: subCategoryId } = subCategory || {};
    const { value: originCountryId } = originCountry || {};
    const { value: manufacturingCountryId } = manufacturingCountry || {};

    const ingredientInfoObject = formatIngredientInfo(data);

    const labelName = convertEditorStateToHtml(latinName);

    await createIngredient({
      variables: {
        createIngredientInput: {
          type,
          name,
          latinName: labelName,
          bulkDensity,
          productTypes,
          lossOnDrying,
          tappedDensity,
          tradeMarkName,
          scientificName,
          cost: parseFloat(cost),
          categoryId: categoryId,
          amount: parseFloat(amount),
          subCategoryId: subCategoryId,
          potency: `${potencyInDecimal}`,
          shelfLife: parseFloat(shelfLife),
          ingredientAllergens: allergens,
          originCountry: originCountryId,
          ingredientSuppliers: suppliersList,
          ingredientInfo: ingredientInfoObject,
          avergageParticleSize: averageParticleSize,
          manufacturingCountry: manufacturingCountryId,
          gummyOverage: parseFloat(gummyOverage),
          suggestedOverage: parseFloat(suggestedOverage),
          nutrients,
          nutrientName,
          showB3,
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_INGREDIENT_TEXT}</Typography>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              id="create-ingredient-btn"
              disabled={createIngredientLoading}>
              {ADD_INGREDIENT_TEXT}
            </Button>
          </Box>
          <IngredientForm loading={createIngredientLoading} />
        </form>
      </FormProvider>
      <BackdropLoader open={createIngredientLoading} text={INGREDIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default AddIngredient;
