import { useFormContext } from 'react-hook-form';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { FC, Fragment, Reducer, SyntheticEvent, useMemo, useReducer } from 'react';
// components
import General from './components/General';
import Allergens from './components/Allergens';
import Nutrients from './components/Nutrients';
import HeavyMetals from './components/HeavyMetals';
import TabPanel from 'components/common/TabPanel';
// interfaces, constants, reducer
import { AddNewIngredientFormType, isLoadingType } from 'interfaces';
import { INGREDIENT_TABS, IngredientTabsEnum } from 'constants/index';
import { Action, ActionType, State, ingredientReducer, initialState } from 'reducer/ingredientReducer';

const IngredientForm: FC<isLoadingType> = ({ loading }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(ingredientReducer, initialState);
  const { activeTab } = state;

  const { watch } = useFormContext<AddNewIngredientFormType>();
  const { amount, nutrients } = watch();

  const tabMappedData = useMemo(() => {
    if (amount === '100' || (amount === '1' && nutrients)) {
      return INGREDIENT_TABS;
    } else {
      return INGREDIENT_TABS?.filter(({ value }) => value !== IngredientTabsEnum.NUTRIENTS);
    }
  }, [amount, nutrients]);

  const tabHandler = (_: SyntheticEvent, activeTab: IngredientTabsEnum) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab });
  };

  return (
    <Fragment>
      <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
        {tabMappedData?.map((item) => {
          const { name, value } = item;

          return <Tab value={value} key={value} label={name} id={value} iconPosition="start" />;
        })}
      </Tabs>

      <Box pt={4}>
        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.GENERAL}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <General loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.NUTRIENTS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <Nutrients loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.HEAVY_METALS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <HeavyMetals loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.ALLERGENS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <Allergens loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default IngredientForm;
